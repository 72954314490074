<template>
  <div class="ns-dev">
    <div
      class="page-header d-flex align-items-center justify-content-center"
      style="
        background: url('/img/nearshore/NearshoreTechnicalSupport-header.jpg');
        background-size: cover;
      "
    >
      <div class="viewport">
        <h1>Maintain efficient operations and improve end-user satisfaction</h1>
        <p>with our dedicated nearshore support center model</p>
      </div>
      <div class="overlay"></div>
    </div>
    <div class="viewport">
      <div class="content">
        <p>
          As your business grows, meeting customer expectations and keeping a high service level
          becomes increasingly challenging. By using our dedicated nearshore support center model
          you can enjoy peace of mind and provide high-class technical support at all times. As a
          natural extension of your team, your nearshore support staff will be able to help resolve
          issues and answer questions to your customers or internal organisation, no matter the time
          of day.
        </p>
        <div class="boxes d-flex justify-content-between">
          <div class="box">
            <img src="/img/icons/icons8-trophy-100.png" alt="icon" />
            <h3>Knowledge and expertise</h3>
            <p>
              We provide competent and service-minded support professionals. By aligning processes
              and systems in co-operation with you, we are ensuring high quality operations.
            </p>
          </div>

          <div class="box">
            <img src="/img/icons/icons8-tape-measure-100.png" alt="icon" />
            <h3>Flexible and adaptable</h3>
            <p>
              Your nearshore technical support staff can easily accommodate new product or feature
              launches, remote desktop support, updates and troubleshooting.
            </p>
          </div>

          <div class="box">
            <img src="/img/icons/icons8-location-100.png" alt="icon" />
            <h3>Closer than you think</h3>
            <p>
              Based in Sofia, Bulgaria, your technical support team is ideally placed in a European
              time zone and within easy reach.
            </p>
          </div>

          <div class="box">
            <img src="/img/icons/icons8-home-office-100.png" alt="icon" />
            <h3>The right tools for the job</h3>
            <p>
              Your nearshore support team will work out of our comfortable office, using modern
              equipment in an enjoyable working environment.
            </p>
          </div>

          <div class="box">
            <img src="/img/icons/icons8-heart-puzzle-100.png" alt="icon" />
            <h3>Full-service</h3>
            <p>
              You’ll have access to our local management to oversee your staff and operations, as
              well as local HR team to look after, and scale your team as required.
            </p>
          </div>
        </div>
        <div class="whitepaper">
          <h3>How to gain a business advantage with outsourced technical support</h3>
          <p>
            For businesses of all sizes and industries, competition has never been tougher. From new
            market entrants and disruptors through to the drag of legacy systems and financial
            blockers, today’s companies need to adapt to the new ways of working or risk getting
            left behind. As a business owner or employee, you already know the importance of finding
            and maintaining your competitive advantage: and part of that advantage is how well you
            serve your customers and partners. According to Salesforce, 84% of consumers say that
            the experience a company provides is as important as its products and services. To
            deliver that experience, you’ll need to deliver on a number of success factors,
            including the provision of first-class customer support that is more technical and more
            useful. But how can you provide that support in a way that’s not only cost-effective but
            elevates your competitive advantage? In the whitepaper below, we are going to take a
            closer look at technical nearshoring support, together with how it can help your
            business maintain market leadership.
          </p>
          <div class="text-center">
            <a
              href="https://comstream.eu/whitepapers/Nearshore_technical_support.pdf"
              class="btn btn-primary"
              download
              >Download Whitepaper</a
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="background-mid-section d-flex align-items-center"
      style="
        background: url('/img/nearshore/NearshoreTechnicalSupport-bg.jpg');
        background-size: cover;
        background-position: top;
        margin: 20px 0 0 0;
      "
    >
      <div class="viewport">
        <h3 style="color: #000">Nearshore software development from highly skilled developers</h3>
        <p style="color: #000">
          If you need to expand your software development capacity, you can build a tailor-made
          nearshore development team with us.
        </p>
        <router-link
          to="/services/dedicated-nearshore-team/nearshore-development"
          class="btn btn-primary"
          >Read more</router-link
        >
      </div>
      <div class="overlay" style="background: rgba(255, 255, 255, 0.5)"></div>
    </div>
  </div>
</template>

<script>
import titleMixin from '../mixins/titleMixin';
export default {
  mixins: [titleMixin],
  title: 'Outsourced IT Support | Comstream',
  mounted() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  },
};
</script>

<style lang="scss" scoped>
.ns-dev {
  .content {
    padding: 70px 0;
    .boxes {
      .box {
        width: 19%;
        img {
          width: 100px;
          height: 100px;
          display: block;
          margin: 12px auto;
        }
        h3 {
          font-size: 24px;
          font-weight: 700;
          text-align: center;
          margin: 0 0px 12px 0;
          color: #000;
        }
        p {
          font-size: 16px;
        }
      }
    }
    .whitepaper {
      padding: 70px 0;
      h3 {
        font-size: 38px;
        text-align: center;
        font-weight: 700;
        padding: 0 10%;
        margin-bottom: 25px;
      }
    }
  }
}
@media only screen and (max-width: 576px) {
  .ns-dev {
    .content {
      .boxes {
        display: block !important;
        .box {
          width: 100%;
        }
      }
      .whitepaper {
        h3 {
          font-size: 1.8rem;
          padding: 0 !important;
        }
      }
    }
  }
}
</style>
